@use '@carbon/grid';

@import "./globals/colors.scss";
@import '@carbon/layout/index.scss';

@include grid.flex-grid();

$spacing-mid: $spacing-03+$spacing-10;
// margin-left: $spacing-mid;

.disable-notify-bar~.homenew-layout {
    margin-top: 160px;

    @media only screen and (max-width: 1055.9px) {
        margin-top: 96px;
    }
}

.homenew-layout {
    margin-top: 200px;

    @media only screen and (max-width: 1055.9px) {
        margin-top: 136px;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .text-align-center {
        text-align: center;
    }

    .display-inline-block {
        display: inline-block;
    }

    h2.display-inline-block {
        max-width: calc(100% - 100px);
    }

    .cds--grid {
        @media only screen and (max-width: 671.9px) {
            padding-left: $spacing-05;
            padding-right: $spacing-05;
        }
    }

    a.web-btn {
        padding: 8px 24px;
        font-size: 16px;
        line-height: 1.5;
        color: $Jaguar;
        background: $white;
        border: none;
        border-radius: 48px;
        user-select: none;
        min-width: 132px;
        text-align: center;
        transition: none;

        @media only screen and (max-width: 1055.9px) {
            font-size: 14px;
            line-height: 24px;
        }

        @media only screen and (max-width: 671.9px) {
            min-width: 122px;
        }

        &:hover {
            background: $Jaguar;
            color: $white;
        }
    }

    a.learn-more,
    a.see-all {
        padding: 8px 24px;
        font-size: 16px;
        line-height: 1.5;
        color: $white;
        text-decoration: none;
        user-select: none;
        position: relative;
        border-radius: 48px;

        @media only screen and (max-width: 1055.9px) {
            font-size: 14px;
            line-height: 24px;
        }

        &:hover {
            background: $white;
            color: $Jaguar;
        }

        &::after {
            content: "";
            position: absolute;
            width: calc(100% - 48px);
            border-bottom: 1px solid;
            bottom: 12px;
            left: 24px;

            @media only screen and (max-width: 671.9px) {
                bottom: 13px;
            }
        }
    }

    a.see-all {
        color: $zenyum_blue;
        display: inline-block;
        float: right;
        margin-right: -24px;

        @media only screen and (max-width: 671.9px) {
            margin-top: -4px;
        }

        &:hover {
            background: $white;
            color: $zenyum_blue;
        }
    }

    .homenew-buttonbox {
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        .sgmy-btn {
            background-color: #005AE2;
            color: $white;
        }

        .sgmy-link {
            color: #005AE2;
        }
    }

    &__banner {
        height: 640px;
        background-size: cover;
        background-position: center;
        color: $white;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media only screen and (max-width: 1919.9px) {
            padding-bottom: 33.33%;
            height: 0;
            display: block;
            position: relative;
        }

        @media only screen and (max-width: 1583.9px) {
            padding-bottom: 35%;
        }

        @media only screen and (max-width: 1311.9px) {
            padding-bottom: 40%;
        }

        @media only screen and (max-width: 1055.9px) {
            padding-bottom: 0;
            height: 400px;
        }

        @media only screen and (max-width: 671.9px) {
            height: 536px;
            // margin-top: 88px;
        }

        >div {
            margin-bottom: $spacing-11;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            max-width: 35%;
            text-align: center;

            @media only screen and (max-width: 1919.9px) {
                position: absolute;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                bottom: 0;
            }

            @media only screen and (max-width: 1583.9px) {
                margin-bottom: $spacing-10;
            }

            @media only screen and (max-width: 1311.9px) {
                margin-bottom: $spacing-08;
            }

            @media only screen and (max-width: 1055.9px) {
                max-width: 370px;
                margin-bottom: $spacing-07;
            }

            @media only screen and (max-width: 671.9px) {
                max-width: 100%;
                padding: 0 $spacing-05;
                margin-bottom: $spacing-08;
                text-align: center;
            }
        }

        h1 {
            font-size: 40px;
            line-height: 1.4;
            font-weight: 500;
            margin: 0;

            @media only screen and (max-width: 1583.9px) {
                font-size: 36px;
            }

            @media only screen and (max-width: 1311.9px) {
                font-size: 32px;
            }

            @media only screen and (max-width: 1055.9px) {
                font-size: 30px;
            }

            @media only screen and (max-width: 671.9px) {
                font-size: 28px;
            }
        }

        p {
            font-size: 16px;
            line-height: 1.5;
            margin: 0;
            margin-top: $spacing-05;

            @media only screen and (max-width: 1055.9px) {
                margin-top: $spacing-04;
            }

            @media only screen and (max-width: 671.9px) {
                margin-top: $spacing-03;
            }

            strong {
                display: block;
                font-family: 'Sofia Pro Semi';
                margin-top: 8px;

                @media only screen and (max-width: 671.9px) {
                    margin-top: 0;
                }
            }
        }

        &.sg-my-banner {

            h1,
            p {
                color: #005AE2;

                @media only screen and (max-width: 1055.9px) {
                    color: $white;
                }
            }
        }

        &.desktop-Dark {

            h1,
            p {
                @media only screen and (min-width: 1056px) {
                    color: $Jaguar
                }
            }
        }

        &.desktop-Light {

            h1,
            p {
                @media only screen and (min-width: 1056px) {
                    color: $white;
                }
            }
        }

        &.mobile-Dark {

            h1,
            p {
                @media only screen and (max-width: 1055.9px) {
                    color: $Jaguar;
                }
            }
        }

        &.mobile-Light {

            h1,
            p {
                @media only screen and (max-width: 1055.9px) {
                    color: $white;
                }
            }
        }

        &.mobile-Zenyum-Blue{

            h1,
            p {
                @media only screen and (max-width: 1055.9px) {
                    color: #005AE2;
                }
            }
        }

        &.desktop-Zenyum-Blue {

            h1,
            p {
                @media only screen and (min-width: 1056px) {
                    color: #005AE2;
                }
            }
        }

        a.web-btn {
            margin-top: $spacing-08;

            @media only screen and (max-width: 1311.9px) {
                margin-top: $spacing-07;
            }

            @media only screen and (max-width: 1055.9px) {
                margin-top: $spacing-06;
            }

            @media only screen and (max-width: 671.9px) {
                margin-top: $spacing-08;
            }
        }

        a.learn-more {
            margin-top: $spacing-03;

            @media only screen and (max-width: 1055.9px) {
                margin-top: $spacing-02;
            }

            @media only screen and (max-width: 671.9px) {
                margin-top: $spacing-03;
            }
        }
    }

    .home-layout-animatedbanner {
        position: relative;

        video {
            width: 100%;
        }

        .main-banner-video {
            @media only screen and (max-width: 1055.9px) {
                display: none;
            }
        }

        .main-banner-video--mobile {
            @media only screen and (min-width: 1056px) {
                display: none;
            }

        }

        .animated-content-wrapper {
            position: absolute;
            width: 100%;
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            z-index: 1;

            @media only screen and (max-width: 1055.9px) {
                align-items: flex-end;
                padding: 0 16px 48px 16px;
                height: calc(100% - 8px);
            }

            .animated-content {
                color: $white;
                text-align: center;
                font-family: 'Sofia Pro Semi';

                h1 {
                    font-weight: 600;
                    font-size: 48px;
                    line-height: 48px;
                    margin: 0 auto;
                    margin-bottom: 16px;
                    max-width: 405px;

                    @media only screen and (max-width: 1055.9px) {
                        font-size: 28px;
                        line-height: 40px;
                        margin: 0;
                        max-width: 345px;
                    }
                }

                p {
                    margin: 0;
                    padding: 0;
                    font-weight: 300;
                    font-size: 20px;
                    font-family: Sofia Pro;
                    line-height: 32px;

                    @media only screen and (max-width: 1055.9px) {

                        font-size: 16px;
                        font-family: Sofia Pro;
                        line-height: 24px;
                    }
                }

                .web-btn {
                    margin-top: 24px;
                    color: #005AE2;
                    width: 352px;
                    padding: 8px 24px;
                }
            }
        }
    }

    &__content {
        margin-top: $spacing-mid;

        &.content-margin-less {
            margin-top: $spacing-08;
        }

        @media only screen and (max-width: 1583.9px) {
            margin-top: $spacing-10;
        }

        @media only screen and (max-width: 1311.9px) {
            margin-top: $spacing-09;
        }

        @media only screen and (max-width: 1055.9px) {
            margin-top: $spacing-08;

            // .cds--row {
            //     margin-left: -0.5rem;
            //     margin-right: -0.5rem;
            // }

            .cds--col-sm-4 {
                // padding-right: 0.5rem;
                // padding-left: 0.5rem;
                flex-basis: 100%;

                &:not(:first-of-type) {
                    margin-top: $spacing-05;
                }
            }
        }

        @media only screen and (max-width: 671.9px) {
            margin-top: $spacing-07;
        }

        h2 {
            font-family: 'Sofia Pro Semi';
            font-size: 32px;
            line-height: 40px;
            color: $Jaguar;
            font-weight: 700;
            margin: 0;

            br {
                display: none;
            }

            @media only screen and (max-width: 1583.9px) {
                font-size: 28px;
            }

            @media only screen and (max-width: 1311.9px) {
                font-size: 24px;
                line-height: 1.5;
            }

            @media only screen and (max-width: 1055.9px) {
                font-size: 22px;
            }

            @media only screen and (max-width: 671.9px) {
                font-size: 20px;
                line-height: 1.6;

                br {
                    display: block;
                }
            }
        }

        .image-box {
            background-size: cover;
            background-position: center;
            border-radius: 16px;
        }

        .content-box-small {
            margin-top: $spacing-06;

            @media only screen and (max-width: 1055.9px) {
                margin-top: $spacing-05;
                // padding-left: 0.5rem;
                // padding-right: 0.5rem;
            }

            @media only screen and (max-width: 671.9px) {
                &:not(:first-of-type) {
                    margin-top: $spacing-03;
                }
            }

            p {
                font-size: 20px;
                line-height: 1.6;
                color: $white;
                margin: 0;
                position: relative;
                z-index: 1;

                @media only screen and (max-width: 1583.9px) {
                    font-size: 18px;
                }

                @media only screen and (max-width: 1055.9px) {
                    font-size: 16px;
                    line-height: 1.5;
                }
            }

            a {
                height: 128px;
                display: flex;
                align-items: center;
                padding-left: $spacing-11;
                text-decoration: none;
                padding-top: 2px;
                position: relative;
                border-radius: 16px;
                overflow: hidden;

                @media only screen and (max-width: 1583.9px) {
                    padding-left: $spacing-09;
                    height: 118px;
                }

                @media only screen and (max-width: 1311.9px) {
                    padding-left: $spacing-07;
                    height: 108px;
                }

                @media only screen and (max-width: 1055.9px) {
                    padding-left: $spacing-07;
                    height: 98px;
                }

                @media only screen and (max-width: 671.9px) {
                    padding-left: $spacing-06;
                    height: 88px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(40, 40, 41, 0.3);
                    left: 0;
                    top: 0;
                    opacity: 0;
                }

                &:hover::after {
                    opacity: 1;
                }
            }
        }

        .content-box-large {
            height: 640px;
            display: flex;
            padding-left: $spacing-11;
            padding-top: $spacing-11;

            @media only screen and (max-width: 1919.9px) {
                padding-bottom: 33.33%;
                height: 0;
                display: block;
                position: relative;
            }

            @media only screen and (max-width: 1583.9px) {
                padding-left: $spacing-09;
                padding-top: $spacing-09;
            }

            @media only screen and (max-width: 1311.9px) {
                padding-left: $spacing-07;
                padding-top: $spacing-07;
            }

            @media only screen and (max-width: 1055.9px) {
                padding-bottom: 50%;
            }

            @media only screen and (max-width: 671.9px) {
                padding-left: $spacing-06;
                padding-top: $spacing-06;
                padding-bottom: 0;
                height: 520px;
            }

            >div {
                width: calc(100% - $spacing-11);

                @media only screen and (max-width: 1919.9px) {
                    position: absolute;
                    left: $spacing-11;
                    top: $spacing-11;
                    width: calc(100% - ($spacing-11 * 2));
                }

                @media only screen and (max-width: 1583.9px) {
                    left: $spacing-09;
                    top: $spacing-09;
                    width: calc(100% - ($spacing-09 * 2));
                }

                @media only screen and (max-width: 1311.9px) {
                    left: $spacing-07;
                    top: $spacing-07;
                    width: calc(100% - ($spacing-07 * 2));
                }

                @media only screen and (max-width: 671.9px) {
                    left: $spacing-06;
                    top: $spacing-06;
                    width: calc(100% - ($spacing-06 * 2));
                }
            }

            h3 {
                font-size: 40px;
                line-height: 1.4;
                font-weight: 500;
                margin: 0;

                @media only screen and (max-width: 1583.9px) {
                    font-size: 34px;
                }

                @media only screen and (max-width: 1311.9px) {
                    font-size: 28px;
                    line-height: 1.5;
                }

                @media only screen and (max-width: 1055.9px) {
                    font-size: 24px;
                }

                @media only screen and (max-width: 671.9px) {
                    font-size: 20px;
                    line-height: 1.6;
                }

                &.heading-small {
                    font-size: 32px;
                    line-height: 1.25;
                    color: $Jaguar;

                    @media only screen and (max-width: 1583.9px) {
                        font-size: 28px;
                    }

                    @media only screen and (max-width: 1311.9px) {
                        font-size: 24px;
                        line-height: 1.5;
                    }

                    @media only screen and (max-width: 1055.9px) {
                        font-size: 22px;
                    }

                    @media only screen and (max-width: 671.9px) {
                        font-size: 20px;
                        line-height: 1.6;
                    }
                }
            }

            p {
                font-size: 16px;
                line-height: 1.5;
                margin: 0;
                margin-top: $spacing-05;
                max-width: 80%;

                @media only screen and (max-width: 1311.9px) {
                    max-width: 90%;
                }

                br {
                    display: none;

                    @media only screen and (max-width: 671.9px) {
                        display: block;
                    }
                }

                @media only screen and (max-width: 671.9px) {
                    margin-top: $spacing-03;
                    max-width: 100%;
                }
            }

            &--half {
                p br {
                    display: block;
                }

                @media only screen and (max-width: 1919.9px) {
                    padding-bottom: 76.1%;
                }

                @media only screen and (max-width: 1055.9px) {
                    padding-bottom: 50%;
                }

                @media only screen and (max-width: 671.9px) {
                    padding-bottom: 0;

                    p br {
                        display: none;
                    }
                }
            }

            a.web-btn {
                margin-top: $spacing-08;
            }

            a.learn-more {
                margin-top: $spacing-03;

                @media only screen and (max-width: 1055.9px) {
                    color: $Jaguar;
                }
            }
        }

        [class*="col-"] {
            @media only screen and (min-width: 1056px) {
                &:not(:nth-of-type(-n+2)) {
                    .content-box-large--half {
                        margin-top: $spacing-07;
                    }
                }
            }

            @media only screen and (max-width: 1055.9px) {
                &:not(:nth-of-type(-n+1)) {
                    .content-box-large--half {
                        margin-top: $spacing-05;
                    }
                }
            }
        }
    }

    &__bannersection {
        margin-top: $spacing-mid;

        @media only screen and (max-width: 1583.9px) {
            margin-top: $spacing-10;
        }

        @media only screen and (max-width: 1311.9px) {
            margin-top: $spacing-09;
        }

        @media only screen and (max-width: 1055.9px) {
            margin-top: $spacing-08;
        }

        @media only screen and (max-width: 671.9px) {
            margin-top: $spacing-07;
        }

        .homenew-layout__content {
            margin-top: $spacing-07;

            @media only screen and (max-width: 1583.9px) {
                margin-top: $spacing-06;
            }

            @media only screen and (max-width: 1055.9px) {
                margin-top: $spacing-05;
            }

            &:first-of-type:last-of-type {
                margin-bottom: $spacing-mid;

                @media only screen and (max-width: 1583.9px) {
                    margin-bottom: $spacing-10;
                }

                @media only screen and (max-width: 1311.9px) {
                    margin-bottom: $spacing-09;
                }

                @media only screen and (max-width: 1055.9px) {
                    margin-bottom: $spacing-08;
                }

                @media only screen and (max-width: 671.9px) {
                    margin-bottom: $spacing-07;
                }
            }
        }
    }

    &__slider {
        margin-top: $spacing-06;
        margin-left: -8px;
        width: calc(100% + 16px);

        @media only screen and (max-width: 1055.9px) {
            margin-top: $spacing-05;
        }

        .slick-list {
            overflow: visible;
        }

        .slide-wrapper {
            padding: 0 8px;
        }

        img {
            width: 100%;
        }

        &.subbanner-slider {
            margin-left: -16px;

            .slide-wrapper {
                padding: 0 16px;

                @media only screen and (max-width: 1025px) {
                    padding-right: 0;
                }
            }

            .slick-slide {
                @media only screen and (max-width: 1025px) {
                    width: 100% !important;
                }
            }
        }

        .slider-box {
            &__image {
                background: #F3F3F3;
                border-radius: 16px;
                overflow: hidden;
            }

            &__tags {
                height: 40px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding-right: $spacing-05;

                span {
                    padding: 4px 12px;
                    background: $Jaguar;
                    color: $white;
                    font-size: 12px;
                    line-height: 16px;
                    border-radius: 16px;
                }
            }

            &__content {
                margin-top: $spacing-05;

                @media only screen and (max-width: 1055.9px) {
                    margin-top: $spacing-03;
                }

                span {
                    font-size: 14px;
                    line-height: 24px;
                    color: $zenyum_blue;
                    display: block;
                    height: 24px;

                    @media only screen and (max-width: 1055.9px) {
                        height: 16px;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

                h4 {
                    font-size: 16px;
                    line-height: 1.5;
                    color: $Jaguar;
                    font-family: 'Sofia Pro Semi';
                    margin: 0;
                }

                p {
                    margin: 0;
                    margin-top: $spacing-03;
                }

                s,
                b {
                    font-size: 16px;
                    line-height: 1.5;
                    color: $Jaguar;
                    font-weight: normal;
                    margin: 0;

                    @media only screen and (max-width: 671.9px) {
                        font-size: 14px;
                        display: inline-block;
                    }
                }

                s {
                    color: #999999;
                    margin-right: $spacing-03;
                }
            }
        }

        .slick-prev,
        .slick-next {
            display: block;
            width: 168px;
            height: 100%;
            left: 8px;
            z-index: 1;
            opacity: 0;
            cursor: default;

            @media only screen and (max-width: 1583.9px) {
                width: 120px;
            }

            @media only screen and (max-width: 1055.9px) {
                width: 60px;
            }

            &::before {
                content: "";
                background-image: url('/img/home-new/circle-arrow.svg');
                width: 40px;
                height: 40px;
                background-size: cover;
                background-position: center;
                position: absolute;
                left: 64px;
                top: 50%;
                transform: translate(0, -50%);
                cursor: pointer;

                @media only screen and (max-width: 1583.9px) {
                    left: 32px;
                }

                @media only screen and (max-width: 1055.9px) {
                    left: 16px;
                }
            }

            &:hover {
                opacity: 1;
            }
        }

        .slick-next {
            right: 8px;
            left: auto;

            &::before {
                transform: rotate(180deg);
                right: 64px;
                left: auto;

                @media only screen and (max-width: 1583.9px) {
                    right: 32px;
                }

                @media only screen and (max-width: 1055.9px) {
                    right: 16px;
                }
            }
        }
    }

    .rating-card-wrapper {
        display: none;
    }

    .user-ratings .web-btn {
        border: 1px solid $Jaguar;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin: $spacing-07 0 $spacing-mid;

        @media only screen and (max-width: 1583.9px) {
            margin: $spacing-08 0 $spacing-08;
        }

        @media only screen and (max-width: 1055.9px) {
            margin: $spacing-08 0 $spacing-07;
        }
    }

    .user-ratings {
        margin-top: $spacing-07;

        .ratings-wrapper {
            width: 1504px;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    .instafeed-section {
        padding-top: 0;
        padding-bottom: $spacing-mid;
        margin-top: $spacing-mid;

        @media only screen and (max-width: 1583.9px) {
            margin-top: $spacing-10;
            padding-bottom: $spacing-10;
        }

        @media only screen and (max-width: 1311.9px) {
            margin-top: $spacing-09;
            padding-bottom: $spacing-09;
        }

        @media only screen and (max-width: 1055.9px) {
            margin-top: $spacing-08;
            padding-bottom: $spacing-08;
        }

        @media only screen and (max-width: 671.9px) {
            margin-top: $spacing-07;
            padding-bottom: $spacing-07;
        }
    }
}

.react-horizontal-scrolling-menu--scroll-container {
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #E6E6E6;
    }

    &::-webkit-scrollbar {
        height: 8px;
        background-color: #E6E6E6;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #6E6E73;
        cursor: pointer;
    }
}

.react-horizontal-scrolling-menu--item {
    min-width: 29.79%;
    margin-right: 32px;
    width: 50%;

    @media only screen and (max-width: 1055.9px) {
        min-width: none;
        width: 100%;
        margin: 0;
    }

    .slide-wrapper {
        width: 100%;
        padding-bottom: 24px;

        @media only screen and (max-width: 1055.9px) {
            padding: 0;
        }

        a {
            user-select: none;
            -webkit-user-drag: none;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }
}

@media only screen and (max-width: 1055.9px) {
    .homenew-layout__content {

        .react-horizontal-scrolling-menu--scroll-container,
        .react-horizontal-scrolling-menu--inner-wrapper,
        .react-horizontal-scrolling-menu--wrapper {
            display: block;
        }

        @media only screen and (max-width: 671.9px) {
            .react-horizontal-scrolling-menu--item .content-box-small {
                margin-top: $spacing-03;
            }
        }
    }
}

.banner-skeleton-loading {
    content: '';
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5%;
    width: 100%;
    height: 640px;
    animation: skeleton-loading 2s linear infinite alternate;
    position: relative;

    .banner-content {
        height: 70px;
        width: 30%;
        border-radius: 20px;
        animation: skeleton-loading1 2s linear infinite alternate;
        position: absolute;
        top: 35%;

        @media only screen and (max-width: 991.9px) {
            width: calc(100% - 30%);
        }
    }
    .banner-sub-content {
        height: 30px;
        width: 40%;
        border-radius: 20px;
        animation: skeleton-loading1 1.5s linear infinite alternate;
        position: absolute;
        top: 55%;

        @media only screen and (max-width: 1311.9px) {
            top: 60%;
        }

        @media only screen and (max-width: 991.9px) {
            width: calc(100% - 15%);
            top: 65%;
        }
    }

    @media only screen and (max-width: 1919.9px) {
        padding-bottom: 33.33%;
        height: 0;
    }

    @media only screen and (max-width: 1583.9px) {
        padding-bottom: 35%;
    }

    @media only screen and (max-width: 1311.9px) {
        padding-bottom: 40%;
    }

    @media only screen and (max-width: 1055.9px) {
        padding-bottom: 0;
        height: 400px;
    }

    @media only screen and (max-width: 671.9px) {
        height: 536px;
    }
}

@keyframes skeleton-loading {
    0% {
        background-color: #f0f0f0;
    }

    100% {
        background-color: #dedada;
    }
}

@keyframes skeleton-loading1 {
    0% {
        background-color: #dedada;
    }

    100% {
        background-color: #f0f0f0;
    }
}

.ab-iam-root, .ada_modal_wrap {
    display: block;
}